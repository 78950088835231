import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";



import TagManager from 'react-gtm-module'
const tagManagerArgs = {
	    gtmId: 'GTM-NF7F3CJ'
	}
TagManager.initialize(tagManagerArgs);



import ReactGA from 'react-ga4';
ReactGA.initialize('G-KSM6507PQ9');
//ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.send("pageview");

const loading = (
  <div className="http-err-wrapper">
    <div className="http-err">
      <div className="loader" />
    </div>
  </div>
);
// Containers
const Layout = React.lazy(() => import("./containers/Layout"));
// Pages
const Page404 = React.lazy(() => import("./views/pages/Page404"));
const Page500 = React.lazy(() => import("./views/pages/Page500"));

class App extends Component {
  //
  componentDidMount() {
    // fetch("http://localhost:3002/api")
    // fetch("/api")
    //   .then((res) => res.json())
    //   .then((data) => console.log(data));
  }
  //  
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Main"
              render={(props) => <Layout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
